import { Injectable } from '@angular/core';
import {
  Toast,
  ToastTheme,
} from '@shared/components/base/toasts/models/toast.model';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToastsService {
  private toasts: Toast[] = [];
  private toastSubject = new Subject<Toast[]>();
  public toasts$ = this.toastSubject.asObservable();
  private idCounter = 0;

  addToast(message: string = 'Оповещение', theme: ToastTheme = 'success') {
    this.idCounter++;
    const toast: Toast = {
      id: ++this.idCounter,
      message,
      theme,
    };
    this.toasts = [toast, ...this.toasts];
    this.toastSubject.next(this.toasts);
  }

  removeToast(id: number) {
    this.toasts = this.toasts.filter((a) => a.id !== id);
    this.toastSubject.next(this.toasts);
  }

  removeAll() {
    this.toasts = [];
    this.toastSubject.next(this.toasts);
  }
}
